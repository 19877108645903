<template>
	<div id="background" :class="isDefault ? 'default' : 'themed'">
		<div id="content-container">
			<router-view />
		</div>

		<footer class="section has-text-centered">
			<div  class="container has-text-white is-size-7 pl-2 has-text-weight-light">
				{{ this.urlLabel }}
			</div>
			<div class="container has-text-white is-size-7 pl-2 has-text-weight-light">
				This site is protected by reCAPTCHA. The Google
				<a
					href="https://policies.google.com/privacy"
				>Privacy Policy</a> and
				<a href="https://policies.google.com/terms">Terms of Service</a> apply.
			</div>
		</footer>
	</div>
</template>

<style lang="scss" scoped>
#background {
	position: relative;

	min-height: 100vh;
	//height: calc(100vh + 12px);

	&.default {
		background-image: url("~@/assets/images/reuzer-gradient.jpg");
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&.themed {
		background: radial-gradient(
			circle,
			var(--menu-gradient-2) 0%,
			var(--menu-gradient-1) 100%
		);
	}

	display: flex;
	justify-content: center;
	flex-direction: column;

	#content-container {
		padding-top: 60px;
		padding-bottom: 100px;
	}

	footer {
		position: absolute;
		height: 24px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		text-decoration: none;

		a:link {
			color: #fff;
		}
	}
}
</style>

<script>
import { httpClient } from '../services/httpClient'
import { mapGetters } from 'vuex'
export default {
	created() {
		httpClient.makeGetRequest('authentication/init')
	},
	computed: {
		...mapGetters('theme', ['theme']),
		isDefault() {
			return this.theme?.isReuzer ?? true;
		},
		urlLabel() {
			return this.theme?.isReuzer ? '' : 'Powered by reuzer.com'
		}
	}
}
</script>