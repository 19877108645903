import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { ShowInfoToast } from '@/services/toastService'
import { PlatformModules } from '@/services/platformModules'
import { AccessLevels, RolesToAccessLevel } from './accessLevels'
import { cmsRoutes } from './_cmsRoutes'
import { userRoutes } from './_userRoutes'
import { clientRoutes } from './_clientRoutes'
import { assessmentRoutes } from './_assessmentRoutes'
//import { mapState } from 'vuex'
Vue.use(VueRouter)

const routes = [
	// {
	//   path: "/",
	//   name: "dashboard",
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
	// },
	{
		path: '/login',
		name: 'login',
		meta: { layout: 'public' },
		props: (route) => ({ returnUrl: route.query.returnUrl }),
		component: () => import(/* webpackChunkName: "public" */ '../views/public/Login.vue'),
	},
	{
		path: '/:ownerCode/login',
		name: 'login-themed',
		meta: { layout: 'public' },
		props: true,
		component: () => import(/* webpackChunkName: "public" */ '../views/public/Login.vue'),
	},
	{
		path: '/confirm-account',
		name: 'confirm-account',
		props: (route) => ({ base64payload: route.query.token }),
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/ConfirmAccount.vue'),
	},
	{
		path: '/:ownerCode/confirm-account',
		name: 'confirm-account-themed',
		meta: { layout: 'public' },
		props: (route) => ({ base64payload: route.query.token, ownerCode: route.params.ownerCode }),
		component: () => import(/* webpackChunkName: "public" */ '../views/public/ConfirmAccount.vue'),
	},
	{
		path: '/confirm-email',
		name: 'confirm-email',
		props: (route) => ({ base64payload: route.query.token }),
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/ConfirmEmailAddress.vue'),
	},
	{
		path: '/:ownerCode/confirm-email',
		name: 'confirm-email-themed',
		props: (route) => ({ base64payload: route.query.token, ownerCode: route.params.ownerCode }),
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/ConfirmEmailAddress.vue'),
	},
	{
		path: '/reset-password',
		name: 'initiate-password-reset',
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/InitiatePasswordReset.vue'),
	},
	{
		path: '/:ownerCode/reset-password',
		name: 'initiate-password-reset-themed',
		props: true,
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/InitiatePasswordReset.vue'),
	},
	{
		path: '/reset-password/complete',
		name: 'reset-password',
		props: (route) => ({ base64payload: route.query.token }),
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/ResetPassword.vue'),
	},
	{
		path: '/:ownerCode/reset-password/complete',
		name: 'reset-password-themed',
		props: (route) => ({ base64payload: route.query.token, ownerCode: route.params.ownerCode }),
		meta: { layout: 'public' },
		component: () => import(/* webpackChunkName: "public" */ '../views/public/ResetPassword.vue'),
	},

	// Redirect
	{
		path: '/',
		redirect: '/dashboard'
	},

	//My Dashboard
	{
		path: '/dashboard',
		meta: { breadCrumb: 'Training you can take anywhere.' },
		name: 'dashboard',
		component: () => import(/* webpackChunkName: "index" */ '../views/dashboards/Index.vue'),
	},
	{
		path: '/terms',
		meta: { breadCrumb: 'Training you can take anywhere.' },
		name: 'accept-platform-terms',
		component: () => import(/* webpackChunkName: "index" */ '../views/platformTerms/AcceptPlatformTerms.vue'),
	},
	{
		path: '/welcome',
		meta: { breadCrumb: 'Training you can take anywhere.' },
		name: 'splash-screen',
		component: () => import(/* webpackChunkName: "index" */ '../views/dashboards/SplashScreen.vue'),
	},
	{
		path: '/admin-dashboard',
		meta: { breadCrumb: 'Training you can take anywhere.', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
		name: 'admin-dashboard',
		component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/dashboards/AdministratorDashboard.vue'),
	},

	//My Profile
	{
		path: '/profile',
		meta: { breadCrumb: 'My Profile' },
		name: 'my-profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/my-profile/Index.vue'),
	},

	//My Certificates
	{
		path: '/my-certificates',
		meta: { breadCrumb: 'My Certificates' },
		name: 'my-certificates',
		component: () => import(/* webpackChunkName: "certificates" */ '../views/my-certificates/Index.vue'),
	},

	//My Courses
	{
		path: '/my-courses',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'My Courses' },
		children: [
			{
				path: '',
				name: 'my-courses',
				props: true,
				component: () => import(/* webpackChunkName: "my-courses" */ '../views/my-courses/Index.vue'),
			},
			{
				path: 'player/:userCourseId',
				component: () => import(/* webpackChunkName:  "my-courses" */ '../views/my-courses/CoursePlayer.vue'),
				name: 'course-player',
				props: true,
				meta: { breadCrumb: 'Play Course' },
			},
			{
				path: 'replay/:userCourseId',
				component: () => import(/* webpackChunkName:  "my-courses" */ '../views/my-courses/ReadonlyCoursePlayer.vue'),
				name: 'readonly-course-player',
				props: true,
				meta: { breadCrumb: 'Replay Course' },
			},
		],
	},

	clientRoutes,
	...cmsRoutes,
	...assessmentRoutes,
	userRoutes,

	//Users
	{
		path: '/my-team',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'My Team' },
		children: [
			{
				path: '',
				name: 'direct-reports',
				component: () => import(/* webpackChunkName: "users" */ '../views/users/Index.vue'),
				meta: { accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
			},
			{
				path: 'learning-status/:userId',
				component: () => import(/* webpackChunkName: "direct-reports" */ '../views/direct-reports/UserLearningStatus.vue'),
				name: 'manager-learning-status',
				props: true,
				meta: { breadCrumb: 'Uzer Learning Status', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
			},
		],
	},

	//User Custom Fields
	{
		path: '/user-custom-fields',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'Uzer Custom Fields' },
		children: [
			{
				path: '',
				meta: { breadCrumb: 'Field List', accessLevel: [AccessLevels.Administrator] },
				name: 'user-custom-fields',
				component: () => import(/* webpackChunkName: "userAdmin" */ '../views/userCustomFields/Index.vue'),
			},
		],
	},

	//User Bulk Import
	{
		path: '/user-bulk-import',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'Bulk Uzer Import' },
		children: [
			{
				path: '',
				meta: { breadCrumb: 'Bulk Import', accessLevel: [AccessLevels.Administrator] },
				name: 'user-import',
				component: () => import(/* webpackChunkName: "userAdmin" */ '../views/users/BulkImport.vue'),
			},
			{
				path: 'view-import/:batchId',
				meta: { accessLevel: [AccessLevels.Administrator] },
				props: true,
				name: 'user-import-view-batch',
				component: () => import(/* webpackChunkName: "userAdmin" */ '../views/users/ViewImportBatch.vue'),
			},
		],
	},

	//Course Library
	{
		path: '/course-library',
		meta: { breadCrumb: 'Course Library' },
		name: 'courses',
		component: () => import(/* webpackChunkName: "course-library" */ '@/views/courseLibrary/Index.vue'),
	},

	//Platform Terms & Conditions
	{
		path: '/platform-terms',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'Platform Terms & Conditions' },
		children: [
			{
				path: '',
				name: 'platform-terms',
				component: () => import(/* webpackChunkName: "platform-terms" */ '@/views/platformTerms/Index.vue'),
				meta: { accessLevel: [AccessLevels.SuperOwner] },
			},
			{
				path: 'create',
				component: () => import(/* webpackChunkName: "platform-terms" */ '@/views/platformTerms/CreatePlatformTerms.vue'),
				name: 'create-platform-terms',
				meta: { breadCrumb: 'Create Draft', accessLevel: [AccessLevels.SuperOwner] },
			},
			{
				path: ':termsId',
				component: () => import(/* webpackChunkName: "platform-terms" */ '@/views/platformTerms/EditPlatformTerms.vue'),
				name: 'edit-platform-terms',
				props: true,
				meta: { breadCrumb: 'View', accessLevel: [AccessLevels.SuperOwner] },
			},
		],
	},

	//Manage Courses (Admin and Manager only)
	{
		path: '/manage-courses',
		meta: { breadCrumb: 'Manage Courses' },
		component: {
			render(c) {
				return c('router-view')
			},
		},
		children: [
			{
				path: '',
				name: 'manage-courses',
				component: () => import(/* webpackChunkName: "manageCourse" */ '@/views/manageCourses/AdminManageLibrary.vue'),
				meta: { accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
			},
			{
				path: 'reminders',
				meta: { breadCrumb: 'Reminders', accessLevel: [AccessLevels.Administrator] },
				name: 'course-reminders',
				component: () => import(/* webpackChunkName: "manageCourse" */ '../views/manageCourses/CourseScheduledReminders.vue'),
			},
			{
				path: 'validity-periods',
				meta: { breadCrumb: 'Validity Periods', accessLevel: [AccessLevels.Administrator] },
				name: 'course-validity-periods',
				component: () => import(/* webpackChunkName: "manageCourse" */ '../views/manageCourses/CourseValidityPeriods.vue'),
			},
			{
				path: 'requests',
				meta: { breadCrumb: 'Course Requests', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
				name: 'course-approvals',
				component: () => import(/* webpackChunkName: "manageCourse" */ '../views/course-approvals/Index.vue'),
			},

			{
				path: ':courseId',
				component: () => import(/* webpackChunkName: "manageCourse" */ '@/views/manageCourses/CourseStats.vue'),
				name: 'course-stats',
				meta: { breadCrumb: 'Course Settings', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
				props: true,
			},
			{
				path: ':courseId/assign',
				component: () => import(/* webpackChunkName: "manageCourse" */ '@/views/manageCourses/AssignUsers.vue'),
				name: 'assign-user-course',
				meta: { breadCrumb: 'Assign Uzers', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
				props: true,
			},
			{
				path: ':courseId/assigned',
				component: () => import(/* webpackChunkName: "manageCourse" */ '@/views/manageCourses/AssignedUsers.vue'),
				name: 'assigned-users',
				meta: { breadCrumb: 'Enrolments', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
				props: true,
			},
		],
	},

	//Manage Library (Owner only)
	{
		path: '/manage-library',
		meta: { breadCrumb: 'Manage Library' },
		component: {
			render(c) {
				return c('router-view')
			},
		},
		children: [
			{
				path: '',
				name: 'manage-library',
				component: () => import(/* webpackChunkName: "owner-manage-course" */ '@/views/manageCourseLibrary/OwnerManageLibrary.vue'),
				meta: { accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
			},
			{
				path: 'create',
				name: 'create-course',
				component: () => import(/* webpackChunkName: "owner-manage-course" */ '@/views/manageCourseLibrary/CreateCourse.vue'),

				meta: { accessLevel: [AccessLevels.SuperOwner] },
			},
			{
				path: ':courseId',
				component: {
					render(c) {
						return c('router-view')
					},
				},
				meta: { breadCrumb: 'Course Settings', accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
				children: [
					{
						path: '',
						component: () => import(/* webpackChunkName: "manage-course" */ '@/views/manageCourseLibrary/ViewCourse.vue'),
						name: 'view-course',
						meta: { accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
						props: true,
					},
					{
						path: 'edit',
						name: 'edit-course',
						props: true,
						component: () => import(/* webpackChunkName: "owner-manage-course" */ '@/views/manageCourseLibrary/EditCourse.vue'),

						meta: { accessLevel: [AccessLevels.SuperOwner] },
					},
				],
			},
		],
	},

	//Reports
	{
		path: '/reports',
		meta: { breadCrumb: 'Reports' },
		name: 'reports',
		component: () => import(/* webpackChunkName: "reports" */ '../views/reports/Index.vue'),
	},

	//(Anonymous) Certificates
	{
		path: '/view-certificate/:userCourseId',
		meta: { layout: 'public' },
		name: 'view-certificate',
		props: true,
		component: () => import(/* webpackChunkName: "view-certificate" */ '../views/view-certificate/Index.vue'),
	},

	//Owners
	{
		path: '/owners',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'Owners' },
		children: [
			{
				path: '',
				name: 'owners',
				component: () => import(/* webpackChunkName: "super-owner" */ '@/views/owners/Index.vue'),
				meta: { accessLevel: [AccessLevels.SuperOwner] },
			},
			{
				path: 'create',
				component: () => import(/* webpackChunkName: "super-owner" */ '../views/owners/CreateOwner.vue'),
				name: 'create-owner',
				meta: { breadCrumb: 'Create Owner', accessLevel: [AccessLevels.SuperOwner] },
			},
			{
				path: 'edit/:ownerId',
				component: () => import(/* webpackChunkName: "super-owner" */ '../views/owners/EditOwner.vue'),
				name: 'edit-owner',
				props: true,
				meta: { breadCrumb: 'Edit Owner', accessLevel: [AccessLevels.SuperOwner] },
			},
			{
				path: ':ownerId',
				component: () => import(/* webpackChunkName: "super-owner" */ '../views/owners/ViewOwner.vue'),
				name: 'view-owner',
				props: true,
				meta: { breadCrumb: 'View Owner', accessLevel: [AccessLevels.SuperOwner] },
			},
		],
	},

	//Error Pages
	{
		path: '/error',
		name: 'error',
		meta: { breadCrumb: 'Error', errorPage: true },
		component: () => import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
	},
	{
		path: '/error/:errorNumber',
		name: 'errorNo',
		meta: { breadCrumb: 'Error', errorPage: true },
		props: true,
		component: () => import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
	},
	{
		path: '/:ownerCode/error',
		name: 'error-themed',
		meta: { breadCrumb: 'Error', errorPage: true },
		props: true,
		component: () => import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
	},
	{
		path: '/:ownerCode/error/:errorNumber',
		name: 'errorNo-themed',
		meta: { breadCrumb: 'Error', errorPage: true },
		props: true,
		component: () => import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
	},

	//Default redirect
	{
		path: '*',
		redirect: '/error/404',
		meta: { errorPage: true },
	},
]

export const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

function redirectToTermsAcceptance() {
	return store.getters['authentication/redirectToTermsAcceptance'] || false
}

function isLoggedIn() {
	return store.getters['authentication/isAuthenticated'] || false
}

// function currentRole() {
// 	return store.getters['authentication/roles'] || []
// }

function getCurrentUserAccessLevel() {
	const accessLevel = store.getters['authentication/roles'] || [AccessLevels.Default]
	return RolesToAccessLevel(accessLevel)
}

function getOwnerCode() {
	return store.getters['theme/ownerCode'] || ''
}

function hasCMS() {
	return store.getters['authentication/hasCMS'] || false
}

router.beforeEach(async (to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = [
		'login',
		'login-themed',
		'reset-password',
		'reset-password-themed',
		'confirm-account',
		'confirm-account-themed',
		'initiate-password-reset',
		'initiate-password-reset-themed',
		'confirm-email',
		'confirm-email-themed',
		'view-certificate'
	]
	const isPublicPage = publicPages.includes(to.name)
	const loggedIn = isLoggedIn()
	const redirectToTerms = redirectToTermsAcceptance()

	//console.log(`Route: ${to.path}, Name: ${to.name}, IsPublic: ${isPublicPage}, IsLoggedIn: ${loggedIn}, IsErrorPage: ${to.meta.errorPage}`)
	if (to.meta.errorPage && loggedIn) {
		return next()
	} else if (to.meta.errorPage && !loggedIn) {
		return next('/login')
	} else if (!isPublicPage && !loggedIn) {
		const ownerCode = getOwnerCode()
		const path = to.path === '/' ? 'login' : to.path
		const page = ownerCode ? `/${ownerCode}/${path}` : `/${path}`
		return next(page)
	} else if (isPublicPage && !loggedIn) {
		return next()
	}
	//check access to platform modules
	else if (to.meta.requiredModule && to.meta.requiredModule === PlatformModules.CMS && !hasCMS()) {
		console.warn('No Access to Module')
		return next('/error/403')
	}
	//Check user has required access level to each matched route
	else if (to.matched.some((route) => isRestrictedAccessLevel(route))) {
		console.warn('Unsufficient Access Level')
		return next('/error/403')
	}

	//Finally if user has all required modules and access levels, check they have accepted platform T&C's
	else if (redirectToTerms & (to.name !== 'accept-platform-terms')) {
		ShowInfoToast("Please review the 'Terms and Conditions' before accessing the platform")
		return next('/terms')
	} else {
		next()
	}
})

const isRestrictedAccessLevel = (route) => {
	if (!route.meta.accessLevel || route.meta.accessLevel.length === 0) return false

	// As of Apr2023 (OTJ feature) access levels are now calculated using JS bit/binary flags.

	const requiredAccessLevel = [...new Set(route.meta.accessLevel)].reduce((prev, curr) => prev + curr, 0)
	const userAccessLevel = getCurrentUserAccessLevel()

	return !(userAccessLevel & requiredAccessLevel)
}
