
var prefix = 'reuzer';
var iconName = 'course';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f502';
var svgPathData = `
    M990.564 152.555h-957.127c-18.457 0-33.437 14.979-33.437 33.437v652.016c0 18.457 14.979 33.437 33.437 33.437h957.127c18.457 0 33.437-14.979 33.437-33.437v-652.016c0-18.457-14.963-33.437-33.437-33.437zM957.128 804.571h-890.252v-585.143h890.252v585.143z
    M309.458 690.234c5.601 3.745 12.087 5.667 18.641 5.667 4.28 0 8.593-0.819 12.656-2.491l367.805-150.465c12.573-5.15 20.781-17.37 20.781-30.945s-8.209-25.797-20.781-30.945l-367.805-150.465c-10.282-4.213-22.034-3.026-31.297 3.176-9.246 6.219-14.795 16.618-14.795 27.769v300.93c0 11.151 5.551 21.55 14.795 27.769zM361.536 411.338l246.061 100.662-246.061 100.662v-201.322z
`

export const reuzerCourse = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};