import { AccessLevels } from './accessLevels'
import { PlatformModules } from '@/services/platformModules'

export const userRoutes = {
	path: '/users',
	component: {
		render(c) {
			return c('router-view')
		},
	},
	meta: { breadCrumb: 'Uzer List' },
	children: [
		{
			path: '',
			name: 'users',
			component: () => import(/* webpackChunkName: "users" */ '../views/users/Index.vue'),
			meta: { accessLevel: [AccessLevels.Administrator] },
		},
		{
			path: 'admin/locations',
			component: () => import(/* webpackChunkName: "manageCourse" */ '../views/userLocations/Index.vue'),

			meta: { breadCrumb: 'Uzer Locations', accessLevel: [AccessLevels.Administrator] },
			name: 'user-locations',
		},
		{
			path: 'create',
			component: () => import(/* webpackChunkName: "userAdmin" */ '../views/users/CreateUser.vue'),
			name: 'create-user',
			props: true,
			meta: { breadCrumb: 'Create Uzer', accessLevel: [AccessLevels.Administrator] },
		},
		{
			path: ':userId',
			component: () => import(/* webpackChunkName: "userAdmin" */ '../views/users/UserProfile.vue'),
			name: 'user-profile',
			props: true,
			meta: { breadCrumb: 'View Profile', accessLevel: [AccessLevels.Manager, AccessLevels.Administrator] },
		},
		{
			path: ':userId/edit',
			component: () => import(/* webpackChunkName: "userAdmin" */ '../views/users/EditUser.vue'),
			name: 'edit-user',
			props: true,
			meta: { breadCrumb: 'Edit Uzer', accessLevel: [AccessLevels.Administrator] },
		},
		{
			path: ':userId/learning-status',
			component: () => import(/* webpackChunkName: "userAdmin" */ '../views/users/UserLearningStatus.vue'),
			name: 'learning-status',
			props: true,
			meta: { breadCrumb: 'Learning Status', accessLevel: [AccessLevels.Administrator] },
		},
		{
			path: ':userId/cms',
			meta: {
				breadCrumb: 'Uzer Competence Summary',
				accessLevel: [AccessLevels.Administrator, AccessLevels.Manager, AccessLevels.Assessor],
				requiredModule: PlatformModules.CMS,
			},
			name: 'user-competence-matrix-summary',
			props: true,
			component: () => import(/* webpackChunkName: "assessment" */ '../views/cms/UserCompetenceMatrixSummary.vue'),
		},
		{
			path: ':userId/assign-profile-to-user/',
			meta: {
				breadCrumb: 'Assign Profiles to Uzer',
				accessLevel: [AccessLevels.Administrator, AccessLevels.Manager],
				requiredModule: PlatformModules.CMS,
			},
			props: true,
			name: 'assign-competence-profiles-to-user',
			component: () => import(/* webpackChunkName: "cms" */ '../views/cms/AssignProfilesToUser.vue'),
		},
	],
}
