import { ColorUpdater } from 'bulma-css-vars'
import { bulmaCssVariablesDefs } from '@/assets/styles/css-variables/bulma-colors'

export function changeColour(colourName, hslColour) {
	if (colourName && hslColour) {
		const colorUpdater = new ColorUpdater(bulmaCssVariablesDefs)
		const hsl = { h: hslColour.h, s: hslColour.s * 100, l: hslColour.l * 100 }
		colorUpdater.updateVarsInDocument(colourName, hsl)
	}
}

export function toHslString(hsl) {
	if (hsl && 'h' in hsl && 's' in hsl && 'l' in hsl) {
		return `hsla(${hsl.h.toFixed(0)},${(100 * hsl.s).toFixed(0)}%,${(100 * hsl.l).toFixed(0)}%,1.0)`
	} else return 'hsla(0,100%,100%,1.0)'
}
