
var prefix = 'reuzer';
var iconName = 'user';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f505';
var svgPathData = `
M630.852 542.771c41.609-33.911 68.164-85.197 68.164-142.49 0-73.745-44.117-137.37-107.571-166.963-1.314-0.785-2.594-1.638-4.045-2.27-6.349-2.748-12.937-5.018-19.575-7.066-0.495-0.154-1.007-0.273-1.502-0.427-3.55-1.058-7.1-2.185-10.701-3.021-1.229-0.29-2.509-0.393-3.738-0.649-3.447-0.751-6.946-1.263-10.462-1.826-3.055-0.478-6.093-1.058-9.182-1.382-5.82-0.631-11.708-0.939-17.681-1.024-0.802-0.017-1.587-0.137-2.389-0.137-0.068 0-0.119 0.017-0.171 0.017s-0.119-0.017-0.171-0.017c-0.802 0-1.587 0.119-2.389 0.137-5.956 0.085-11.861 0.393-17.681 1.024-3.089 0.324-6.11 0.905-9.182 1.382-3.516 0.546-7.014 1.075-10.462 1.826-1.229 0.273-2.509 0.358-3.738 0.649-3.601 0.836-7.151 1.963-10.701 3.021-0.495 0.154-1.007 0.273-1.502 0.427-6.639 2.048-13.227 4.318-19.575 7.066-1.451 0.631-2.731 1.468-4.045 2.27-63.454 29.611-107.571 93.218-107.571 166.963 0 57.293 26.556 108.578 68.164 142.49-84.838 42.684-143.36 129.434-144.128 229.393 0 0.358 0 0.734 0 1.109 0 19.439 15.735 35.209 35.191 35.209h0.017c19.405 0 35.14-15.684 35.226-35.072 0.41-103.817 86.767-188.262 192.546-188.348 105.779 0.102 192.137 84.548 192.529 188.348 0.085 19.388 15.821 35.072 35.226 35.072h0.017c19.456 0 35.191-15.77 35.191-35.209 0-0.375 0-0.751 0-1.109-0.751-99.959-59.273-186.709-144.111-229.393zM512 514.594c-64.29-0.102-116.582-51.337-116.582-114.313 0-47.258 29.423-87.91 71.27-105.301 4.813-1.98 9.779-3.584 14.848-4.915 1.946-0.529 3.891-1.058 5.888-1.485 1.741-0.358 3.516-0.631 5.274-0.922 2.236-0.375 4.454-0.768 6.724-1.007 3.379-0.358 6.793-0.529 10.223-0.597 0.785-0.017 1.553-0.119 2.355-0.119s1.57 0.102 2.355 0.119c3.413 0.068 6.827 0.239 10.206 0.597 2.27 0.239 4.506 0.631 6.741 1.007 1.758 0.29 3.533 0.563 5.274 0.922 1.997 0.427 3.942 0.973 5.888 1.485 5.052 1.331 10.035 2.935 14.848 4.915 41.847 17.408 71.27 58.061 71.27 105.301 0 62.993-52.292 114.227-116.582 114.313z
`

export const reuzerUser = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};