import { ShowWarningToast } from '../services/toastService'
import { httpClient } from '../services/httpClient'
import { changeColour, toHslString } from '@/services/themeUtilities'

export const theme = {
	namespaced: true,
	state: {
		theme: {},
	},
	getters: {
		theme: (state) => state.theme,
		ownerCode: (state) => state.theme.ownerCode,
	},
	mutations: {
		setTheme(state, value) {
			state.theme = value
		},
		setOwnerCode(state, value) {
			state.theme.ownerCode = value
		},
	},
	actions: {
		async getTheme({ commit, dispatch }) {
			try {
				const response = await httpClient.makeGetRequest('theme')
				if (response) {
					commit('setTheme', response)
					dispatch('loadTheme')
				}
			} catch (error) {
				console.log(error)
				ShowWarningToast(error.toString())
			}
		},
		async getLoginTheme({ commit, dispatch }, ownerCode) {
			try {
				const response = await httpClient.makeGetRequest(`theme/${ownerCode}`)
				if (response) {
					commit('setTheme', response)
					dispatch('loadTheme')
				}
			} catch (error) {
				console.log(error)
				ShowWarningToast(error.toString())
			}
		},
		async loadTheme({ getters }) {
			const theme = getters.theme
			changeColour('primary', theme.primaryColour)
			changeColour('secondary', theme.secondaryColour)
			changeColour('tertiary', theme.tertiaryColour)
			changeColour('cta', theme.callToActionColour)
			document.documentElement.style.setProperty('--menu-gradient-1', toHslString(theme.menuGradient1))
			document.documentElement.style.setProperty('--menu-gradient-2', toHslString(theme.menuGradient2))
		},
		async setTheme({ commit }, theme) {
			commit('setTheme', theme)
		},
	},
}
