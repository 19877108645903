<template>
	<transition name="fade" mode="out-in">
		<component :is="layout"></component>
	</transition>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.grecaptcha-badge {
	visibility: hidden;
}
</style>
<script>
import { mapActions } from 'vuex'
import Public from '@/layouts/public.vue'
import { provide } from 'vue'
//import { useBreakpoints } from '@/services/screenSize'

export default {
	name: 'App',
	components: {
		Public,

		//Lazy load the main layout, so this only loads when user successfully logs in.
		Default: () => import(/* webpackPrefetch: true */ '@/layouts/default.vue'),
	},
	computed: {
		layout() {
			return this.$route.meta.layout || 'default' // + '-layout'
		},
	},
	beforeMount() {
		this.loadTheme()
	},
	beforeDestroy() {
		// Cleanup SignalR event handlers when removing the component
		this.$notificationHub.$off('logout-user', this.logoutSession)
	},
	mounted() {},
	created() {
		this.startSignalR()

		this.$notificationHub.$on('logout-user', this.logoutSession)
		provide('notificationHub', this.$notificationHub) // For Vue 2 Composition components.
		provide('buefy', this.$buefy)

		//const breakpoints = useBreakpoints()
		//provide('breakpoints', breakpoints)

		//This configures the default sizes for the custom SVG icons used.
		const customIconConfig = {
			customIconPacks: {
				reuzer: {
					sizes: {
						default: '2x',
						'is-small': 'lg',
						'is-medium': '2x',
						'is-large': '4x',
					},
					iconPrefix: '',
				},
				// fas: {
				//     sizes: {
				//       default: '',
				//         'is-small': 'xs',
				//         'is-medium': 'lg',
				//         'is-large': '3x',
				//     },
				// },
				// fal: {
				//     sizes: {
				//                 default: '',
				//         'is-small': 'xs',
				//         'is-medium': 'lg',
				//         'is-large': '3x',
				//     },
				// },
				// fab: {
				//     sizes: {
				//      default: '',
				//         'is-small': 'xs',
				//         'is-medium': 'lg',
				//         'is-large': '3x',
				//     },
				// },
			},
		}

		this.$buefy.config.setOptions(customIconConfig)
	},
	methods: {
		...mapActions('authentication', ['startSignalR', 'logoutUI']),
		...mapActions('theme', ['loadTheme']),
		logoutSession() {
			this.logoutUI()
		},
	},
}
</script>
