import { ToastProgrammatic as Toast } from 'buefy';

export function ShowSuccessToast(message) {
    Toast.open({
        message: message.toString(),
        type: 'is-success',
        position: 'is-bottom',
        duration: 1500,
    })
}

export function ShowWarningToast(message, duration) {
    Toast.open({
        message: message.toString(),
        type: 'is-danger',
        position: 'is-bottom',
        duration: duration || 2000,
    })
}

export function ShowInfoToast(message, duration) {
    Toast.open({
        message: message.toString(),
        type: 'is-tertiary',
        position: 'is-bottom',
        duration: duration || 2000,
    })
}