export const AccessLevels = {
	SuperOwner: 64,
	Owner: 32,
	Administrator: 16,
	Manager: 8,
	Verifier: 4,
	Assessor: 2,
	Learner: 1,
}

export function RolesToAccessLevel(roles) {
	const accessLevels = (roles || []).map((x) => AccessLevels[x]).filter((x) => x)
	return [...new Set([...accessLevels, AccessLevels.Learner])].reduce((prev, curr) => prev + curr, 0)
}
