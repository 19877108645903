import { httpClient } from '../services/httpClient'
export const app = {
	namespaced: true,
	state: {
		platformVersionNo: '',
		isAdminMode: false,
	},
	getters: {
		isAdminMode: (state) => state.isAdminMode || false,
		platformVersionNo: (state) => state.platformVersionNo || '',
	},
	mutations: {
		clearAdminMode(state) {
			localStorage.removeItem('isAdminMode')
			state.isAdminMode = false
		},
		setIsAdminMode(state, value) {
			localStorage.setItem('isAdminMode', value)
			state.isAdminMode = value
		},
		setPlatformVersionNo(state, value) {
			state.platformVersionNo = value
		},
	},
	actions: {
		initialiseAdminModeState({ commit }) {
			const isAdminMode = localStorage.getItem('isAdminMode') === 'true' // Boolean is stored as text
			commit('setIsAdminMode', isAdminMode)
		},
		clearAdminMode({ commit }, isAdminMode) {
			commit('clearAdminMode', isAdminMode)
		},
		setIsAdminMode({ commit }, isAdminMode) {
			commit('setIsAdminMode', isAdminMode)
		},
		async getPlatformVersionNo({ commit }) {
			try {
				const response = await httpClient.makeGetRequest('app/platformVersionNo')
				if (response) {
					commit('setPlatformVersionNo', response)
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
