import { AccessLevels } from './accessLevels'
import { PlatformModules } from '@/services/platformModules'

export const cmsRoutes = [
	{
		path: '/cms',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'CMS' },
		children: [
			{
				path: '',
				meta: { breadCrumb: 'CMS', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
				name: 'cms-dashboard',
				component: () => import(/* webpackChunkName: "cms" */ '../views/cms/AdminCompetenceDashboard.vue'),
			},
			{
				path: 'matrix',
				meta: { breadCrumb: 'Matrix', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
				props: true,
				name: 'competence-matrix',
				component: () => import(/* webpackChunkName: "cms" */ '../views/cms/AdminCompetenceMatrix.vue'),
			},
			{
				path: 'competence-profiles',
				component: {
					render(c) {
						return c('router-view')
					},
				},
				meta: { breadCrumb: 'Profiles' },
				children: [
					{
						path: '',
						meta: { accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
						props: true,
						name: 'competence-profiles',
						component: () => import(/* webpackChunkName: "cms" */ '../views/cms/CompetenceProfileList.vue'),
					},
					{
						path: 'create',
						meta: { breadCrumb: 'Create', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
						props: true,
						name: 'create-competence-profile',
						component: () => import(/* webpackChunkName: "cms" */ '../views/cms/CreateCompetenceProfile.vue'),
					},
					{
						path: ':profileId',
						component: {
							render(c) {
								return c('router-view')
							},
						},
						children: [
							{
								path: '',
								meta: { breadCrumb: 'View', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
								props: ({ params, query }) => ({ profileId: params.profileId, startingTab: Number.parseInt(query.startingTab, 10) || 0 }),
								name: 'view-competence-profile',
								component: () => import(/* webpackChunkName: "cms" */ '../views/cms/ViewCompetenceProfile.vue'),
							},
							{
								path: 'edit',
								meta: { breadCrumb: 'Edit', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
								props: true,
								name: 'edit-competence-profile',
								component: () => import(/* webpackChunkName: "cms" */ '../views/cms/EditCompetenceProfile.vue'),
							},
						],
					},
				],
			},
			{
				path: 'elements',
				component: {
					render(c) {
						return c('router-view')
					},
				},
				meta: { breadCrumb: 'Elements' },
				children: [
					{
						path: '',
						meta: { accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
						props: true,
						name: 'competence-elements',
						component: () => import(/* webpackChunkName: "cms" */ '../views/cms/CompetenceElementList.vue'),
					},
					{
						path: 'create/:profileId?',
						meta: { breadCrumb: 'Create', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
						props: true,
						name: 'create-competence-element',
						component: () => import(/* webpackChunkName: "cms" */ '../views/cms/CreateCompetenceElement.vue'),
					},

					{
						path: 'categories',
						meta: { breadCrumb: 'Categories', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
						props: true,
						name: 'competence-element-categories',
						component: () => import(/* webpackChunkName: "cms" */ '../views/cms/CompetenceElementCategoryList.vue'),
					},
					{
						path: ':elementId',
						component: {
							render(c) {
								return c('router-view')
							},
						},
						children: [
							{
								path: '',
								meta: { breadCrumb: 'Edit', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
								props: true,
								name: 'edit-competence-element',
								component: () => import(/* webpackChunkName: "cms" */ '../views/cms/EditCompetenceElement.vue'),
							},
							{
								path: 'assign-to-element',
								meta: { breadCrumb: 'Linked Profiles', accessLevel: [AccessLevels.Administrator], requiredModule: PlatformModules.CMS },
								props: true,
								name: 'assign-competence-profiles-to-element',
								component: () => import(/* webpackChunkName: "cms" */ '../views/cms/AssignProfilesToElement.vue'),
							},
						],
					},
				],
			},
		],
	},
	{
		path: '/cms-profile',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'CMS', requiredModule: PlatformModules.CMS },
		children: [
			{
				path: '',
				meta: { breadCrumb: 'CMS', requiredModule: PlatformModules.CMS },
				name: 'my-cms-matrix',
				component: () => import(/* webpackChunkName: "cms" */ '../views/cms/MyCompetenceMatrix.vue'),
			},
			{
				path: 'element/:elementId',
				meta: { breadCrumb: 'Available Courses', requiredModule: PlatformModules.CMS },
				name: 'cms-element-courses',
				props: true,
				component: () => import(/* webpackChunkName: "cms" */ '../views/cms/CompetenceElementAvailableCourses.vue'),
			},
			{
				path: 'assessments',
				meta: { breadCrumb: 'Assessments', requiredModule: PlatformModules.CMS },
				name: 'user-assessment-list',
				props: true,
				component: () => import(/* webpackChunkName: "cms" */ '../views/assessments/UserAssessmentList.vue'),
			},
			{
				path: 'assessments/:assessmentId',
				meta: { breadCrumb: 'Assessments', requiredModule: PlatformModules.CMS },
				name: 'user-view-assessment',
				props: true,
				component: () => import(/* webpackChunkName: "cms" */ '../views/assessments/ViewAssessment.vue'),
			},
		],
	},
	{
		path: '/my-team-cms',
		meta: { breadCrumb: 'Matrix', accessLevel: [AccessLevels.Manager], requiredModule: PlatformModules.CMS },
		props: true,
		name: 'my-team-competence-matrix',
		component: () => import(/* webpackChunkName: "cms" */ '../views/cms/AdminCompetenceMatrix.vue'),
	},
]
