import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import './services/globalFilters'

//Import Layouts
// import Public from './layouts/public.vue';
// Vue.component('default-layout', Default);
// import Default from './layouts/default.vue';
// Vue.component('public-layout', Public);

//Global Date Formatter
import { formatDate } from '@/services/formatDate'
Vue.prototype.$formatDate = formatDate

//Application Insights
import VueAppInsights from 'vue-application-insights'

if (process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING) {
	Vue.use(VueAppInsights, {
		baseName: 'MainPlatform',
		router,
		trackInitialPageView: true,
		appInsightsConfig: {
			connectionString: process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING,
		},
	})
}

//Google Recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })

//Font Awesome
import { FontAwesomeConfig } from './plugins/font-awesome-config' //Moved to separate file
Vue.use(FontAwesomeConfig)

//Vee-Validate
import { VeeValidateConfig } from './plugins/vee-validate-config'
Vue.use(VeeValidateConfig)

//SignalR
import SignalRHub from './plugins/signalRHub'
Vue.use(SignalRHub)

//SignalR
import { VueBrowserDetect } from './plugins/browserDetection'
Vue.use(VueBrowserDetect)

// Buefy
import Buefy from 'buefy'
//import 'buefy/dist/buefy.css';
Vue.use(Buefy, {
	defaultIconComponent: 'font-awesome-icon',
	defaultIconPack: 'fal',
})

import '@/assets/styles/main.scss'

//Script loaded for Scorm Player
//import LoadScript from 'vue-plugin-load-script';
//Vue.use(LoadScript);

Vue.config.productionTip = false

//Set global window variable
//window.simplifyScorm = {};
//Vue.prototype.ScormPlayer = window.simplifyScorm;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
