
var prefix = 'reuzer';
var iconName = 'arrow-right';
var width = 60;
var height = 60;
var ligatures = [];
var unicode = 'f517';
var svgPathData = `
M49.284,30.709c0.015-0.04,0.032-0.08,0.045-0.121c0.023-0.076,0.039-0.152,0.053-0.229
	c0.01-0.054,0.02-0.107,0.026-0.162c0.006-0.064,0.007-0.127,0.007-0.191c0-0.07-0.001-0.139-0.007-0.209
	c-0.005-0.049-0.014-0.097-0.023-0.145c-0.014-0.082-0.031-0.163-0.056-0.244c-0.012-0.037-0.026-0.073-0.04-0.109
	c-0.032-0.087-0.068-0.171-0.113-0.254c-0.016-0.029-0.035-0.057-0.053-0.086c-0.037-0.061-0.068-0.123-0.112-0.181
	c-0.02-0.026-0.044-0.045-0.065-0.069c-0.012-0.014-0.025-0.026-0.038-0.04c-0.084-0.094-0.174-0.18-0.271-0.254l-11.306-8.668
	c-0.876-0.672-2.132-0.506-2.804,0.371c-0.672,0.876-0.507,2.132,0.37,2.804L41.519,28H12.586c-1.104,0-2,0.896-2,2s0.896,2,2,2
	h28.933l-6.624,5.078c-0.877,0.672-1.043,1.928-0.37,2.804c0.394,0.514,0.987,0.783,1.588,0.783c0.425,0,0.853-0.135,1.216-0.413
	l11.28-8.648c0.091-0.068,0.173-0.148,0.253-0.233c0.029-0.03,0.061-0.058,0.087-0.09c0.018-0.022,0.041-0.037,0.058-0.06
	c0.043-0.057,0.074-0.118,0.11-0.178c0.019-0.03,0.039-0.06,0.056-0.091C49.218,30.874,49.253,30.792,49.284,30.709z

`
export const reuzerArrowRight = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};