
var prefix = 'reuzer';
var iconName = 'bin';
var width = 60;
var height = 60;
var ligatures = [];
var unicode = 'f515';
var svgPathData = `
M43.409,12.628H16.591c-1.104,0-2,0.896-2,2v6.744c0,1.104,0.896,2,2,2c0.097,0,0.191-0.015,0.284-0.029
		v22.029c0,1.104,0.896,2,2,2h22c1.104,0,2-0.896,2-2V23.291c0.171,0.048,0.348,0.081,0.534,0.081c1.104,0,2-0.896,2-2v-6.744
		C45.409,13.523,44.513,12.628,43.409,12.628z M38.875,43.372h-18V24.147h18V43.372z M18.875,20.147
        c-0.097,0-0.191,0.015-0.284,0.029v-3.548h22.818v3.6c-0.171-0.048-0.348-0.081-0.534-0.081H18.875z
M25.875,41.372c1.104,0,2-0.896,2-2V28.147c0-1.104-0.896-2-2-2s-2,0.896-2,2v11.225
        C23.875,40.477,24.771,41.372,25.875,41.372z
M33.875,41.372c1.104,0,2-0.896,2-2V28.147c0-1.104-0.896-2-2-2s-2,0.896-2,2v11.225
		C31.875,40.477,32.771,41.372,33.875,41.372z    

`
export const reuzerBin = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};