
var prefix = 'reuzer';
var iconName = 'eye';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f508';
var svgPathData = `
M512 246.067c-211.388 0-396.885 165.734-396.885 265.933s185.498 265.933 396.885 265.933c211.388 0 396.885-165.751 396.885-265.933s-185.498-265.933-396.885-265.933zM512 709.666c-188.57 0-328.636-151.347-328.636-197.666 0-46.302 140.066-197.649 328.636-197.649s328.619 151.33 328.619 197.649c0 46.302-140.049 197.666-328.619 197.666zM512 372.036c-78.507 0-142.37 62.788-142.37 139.964s63.863 139.964 142.37 139.964 142.37-62.788 142.37-139.964-63.863-139.964-142.37-139.964zM512 583.697c-40.875 0-74.103-32.171-74.103-71.697s33.229-71.697 74.103-71.697 74.103 32.171 74.103 71.697-33.229 71.697-74.103 71.697z
`

export const reuzerEye = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};