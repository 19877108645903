var prefix = 'reuzer'
var iconName = 'cms'
var width = 1024
var height = 1024
var ligatures = []
var unicode = 'f504'
var svgPathData = `
M990.563 152.555h-957.127c-18.457 0-33.437 14.979-33.437 33.437v652.016c0 18.457 14.979 33.437 33.437 33.437h957.127c18.457 0 33.437-14.979 33.437-33.437v-652.016c0-18.457-14.979-33.437-33.437-33.437zM478.563 436.767v150.465h-172.4v-150.465h172.4zM306.163 369.894v-150.465h172.4v150.465h-172.4zM478.563 654.106v150.465h-172.4v-150.465h172.4zM545.437 654.106h172.4v150.465h-172.4v-150.465zM545.437 587.233v-150.465h172.4v150.465h-172.4zM545.437 369.894v-150.465h172.4v150.465h-172.4zM66.873 219.429h172.4v150.465h-172.4v-150.465zM66.873 436.767h172.4v150.465h-172.4v-150.465zM66.873 654.106h172.4v150.465h-172.4v-150.465zM957.127 804.571h-172.416v-150.465h172.4v150.465zM957.127 587.233h-172.416v-150.465h172.4v150.465zM957.127 369.894h-172.416v-150.465h172.4v150.465z
 `

export const reuzerCMS = {
	prefix: prefix,
	iconName: iconName,
	icon: [width, height, ligatures, unicode, svgPathData],
}
