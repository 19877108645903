//Custom Icons
import { reuzerCertificate } from '../assets/icons/certificate'
import { reuzerCourse } from '../assets/icons/course'
import { reuzerDashboard } from '../assets/icons/dashboard'
import { reuzerReport } from '../assets/icons/report'
import { reuzerUsers } from '../assets/icons/users'
import { reuzerUser } from '../assets/icons/user'
import { reuzerBell } from '../assets/icons/bell'
import { reuzerCog } from '../assets/icons/cog'
import { reuzerCogLarge } from '../assets/icons/cog-large'
import { reuzerEye } from '../assets/icons/eye'
import { reuzerDownload } from '../assets/icons/download'
import { reuzerUserDouble } from '../assets/icons/user-double'
import { reuzerPlay } from '../assets/icons/play'
import { reuzerMagnifyingGlass } from '../assets/icons/magnifying-glass'
import { reuzerLibrary } from '../assets/icons/library'
import { reuzerLogout } from '../assets/icons/logout'
import { reuzerPadlock } from '../assets/icons/padlock'
import { reuzerBin } from '../assets/icons/bin'
import { reuzerEdit } from '../assets/icons/edit'
import { reuzerArrowRight } from '../assets/icons/arrow-right'
import { reuzerSuperOwner } from '../assets/icons/super-owner'
import { reuzerCMS } from '../assets/icons/cms'
import { reuzerOTJ } from '../assets/icons/otj'

//See Guide: https://github.com/FortAwesome/vue-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'

import { faHashtag } from '@fortawesome/pro-light-svg-icons/faHashtag'
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment'
import { faShapes } from '@fortawesome/pro-light-svg-icons/faShapes'
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink'
import { faUnlink } from '@fortawesome/pro-light-svg-icons/faUnlink'
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract'
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faFileUser } from '@fortawesome/pro-light-svg-icons/faFileUser'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs'
import { faBooks } from '@fortawesome/pro-light-svg-icons/faBooks'
import { faTachometerAltFast } from '@fortawesome/pro-light-svg-icons/faTachometerAltFast'
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine'
import { faUsersCrown } from '@fortawesome/pro-light-svg-icons/faUsersCrown'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronDoubleLeft } from '@fortawesome/pro-light-svg-icons/faChevronDoubleLeft'
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons/faChevronDoubleRight'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronDoubleUp } from '@fortawesome/pro-light-svg-icons/faChevronDoubleUp'
import { faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons/faChevronDoubleDown'
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate'
import { faUserGraduate } from '@fortawesome/pro-light-svg-icons/faUserGraduate'
import { faHourglassStart } from '@fortawesome/pro-light-svg-icons/faHourglassStart'
import { faFlagCheckered } from '@fortawesome/pro-light-svg-icons/faFlagCheckered'
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons/faPlayCircle'
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory'
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload'
import { faCaretCircleRight } from '@fortawesome/pro-light-svg-icons/faCaretCircleRight'
import { faFolderTree } from '@fortawesome/pro-light-svg-icons/faFolderTree'
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faLineHeight } from '@fortawesome/pro-light-svg-icons/faLineHeight'
import { faUsersSlash } from '@fortawesome/pro-light-svg-icons/faUsersSlash'
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck'
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan'
import { faUnlockAlt } from '@fortawesome/pro-light-svg-icons/faUnlockAlt'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faPoundSign } from '@fortawesome/pro-light-svg-icons/faPoundSign'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion'
import { faHourglass } from '@fortawesome/pro-light-svg-icons/faHourglass'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck'
import { faPercent } from '@fortawesome/pro-light-svg-icons/faPercent'
import { faCommentPlus } from '@fortawesome/pro-light-svg-icons/faCommentPlus'
import { faCameraMovie } from '@fortawesome/pro-light-svg-icons/faCameraMovie'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'

import { faFileImage as faFileImageSolid } from '@fortawesome/pro-solid-svg-icons/faFileImage'
import { faFileVideo as faFileVideoSolid } from '@fortawesome/pro-solid-svg-icons/faFileVideo'
import { faFileWord as faFileWordSolid } from '@fortawesome/pro-solid-svg-icons/faFileWord'
import { faFileExcel as faFileExcelSolid } from '@fortawesome/pro-solid-svg-icons/faFileExcel'
import { faFilePowerpoint as faFilePowerpointSolid } from '@fortawesome/pro-solid-svg-icons/faFilePowerpoint'
import { faFileAlt as faFileAltSolid } from '@fortawesome/pro-solid-svg-icons/faFileAlt'
import { faFilePdf as faFilePdfSolid } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
import { faFile as faFileSolid } from '@fortawesome/pro-solid-svg-icons/faFile'

import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen'
import { faTasks } from '@fortawesome/pro-light-svg-icons/faTasks'
import { faGlobeEurope } from '@fortawesome/pro-light-svg-icons/faGlobeEurope'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv'
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock'
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner'
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo'
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync'

import { faDownload as farDownload } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { faPlay as farPlay } from '@fortawesome/pro-regular-svg-icons/faPlay'
import { faSignOut as farSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut'

import { faQuestionCircle as faQuestionCircleSolid } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faLock as faLockSolid } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faExclamationCircle as faExclamationCircleSolid } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle as faExclamationTriangleSolid } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faUserTag as faUserTagSolid } from '@fortawesome/pro-solid-svg-icons/faUserTag'
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons/faEye'
import { faEyeSlash as faEyeSlashSolid } from '@fortawesome/pro-solid-svg-icons/faEyeSlash'
import { faBuilding as faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'

import { faBold as farBold } from '@fortawesome/pro-regular-svg-icons/faBold'
import { faItalic as farItalic } from '@fortawesome/pro-regular-svg-icons/faItalic'
import { faStrikethrough as farStrikethrough } from '@fortawesome/pro-regular-svg-icons/faStrikethrough'
import { faHorizontalRule as farHorizontalRule } from '@fortawesome/pro-regular-svg-icons/faHorizontalRule'
import { faH1 as farH1 } from '@fortawesome/pro-regular-svg-icons/faH1'
import { faH2 as farH2 } from '@fortawesome/pro-regular-svg-icons/faH2'
import { faH3 as farH3 } from '@fortawesome/pro-regular-svg-icons/faH3'
import { faH4 as farH4 } from '@fortawesome/pro-regular-svg-icons/faH4'
import { faParagraph as farParagraph } from '@fortawesome/pro-regular-svg-icons/faParagraph'
import { faList as farList } from '@fortawesome/pro-regular-svg-icons/faList'
import { faUndo as farUndo } from '@fortawesome/pro-regular-svg-icons/faUndo'
import { faRedo as farRedo } from '@fortawesome/pro-regular-svg-icons/faRedo'
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload'
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload'
//import {} from '@fortawesome/free-solid-svg-icons'; // register each icon used here!

export const FontAwesomeConfig = {
	install(Vue) {
		//Register Global Components here...
		Vue.component('font-awesome-icon', FontAwesomeIcon)
		//Vue.component('font-awesome-layers', FontAwesomeLayers)
		//Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

		//Add Font Awesome icons here (must be imported first - see above)
		library.add(
			farBold,
			farItalic,
			farStrikethrough,
			farHorizontalRule,
			farH1,
			farH2,
			farH3,
			farH4,
			farParagraph,
			farList,
			farUndo,
			farRedo,

			faComment,
			faHashtag,
			faShapes,
			faUserTie,
			faCircle,
			faLink,
			faUnlink,
			faAddressCard,
			faBars,
			faSync,
			faBell,
			faCheck,
			faExclamationCircle,
			faFileUpload,
			faFileDownload,
			faEyeSlash,
			faLock,
			faUser,
			faEye,
			faExclamationCircle,
			faLongArrowRight,
			faArrowUp,
			faPlus,
			faAngleLeft,
			faAngleRight,
			faPhone,
			faGlobe,
			faFileUser,
			faTachometerAltFast,
			faBooks,
			faPercent,
			faFileChartLine,
			faFileContract,
			faCogs,
			faUsersCrown,
			faPencil,
			faUsers,
			faTrashAlt,
			faClipboardListCheck,
			faChevronLeft,
			faChevronRight,
			faChevronDoubleLeft,
			faChevronDoubleRight,
			faChevronUp,
			faChevronDown,
			faChevronDoubleUp,
			faChevronDoubleDown,
			faFileCertificate,
			faUserGraduate,
			faHourglassStart,
			faFlagCheckered,
			faPlayCircle,
			faHistory,
			faCloudDownload,
			faSearch,
			faInfoCircle,
			faEnvelopeSolid,
			faCalendarDay,
			faCog,
			faUpload,
			faCaretCircleRight,
			faFolderTree,
			faChartPie,
			faArrowRight,
			faLineHeight,
			faUsersSlash,
			faUserCheck,
			faBan,
			faUnlockAlt,
			faFilter,
			faExclamationTriangle,
			faEyeSlash,
			faPoundSign,
			faPlay,
			faTimes,
			faArrowLeft,
			faQuestion,
			faHourglass,
			faEnvelope,
			faEnvelopeOpen,
			faTasks,
			faGlobeEurope,
			faClock,
			faFileCsv,
			faAlarmClock,
			faSpinner,
			faRedo,
			faCalendarCheck,
			faBuilding,
			faMinus,
			faCommentPlus,
			faCameraMovie,
			faPaperPlane,

			//Regular
			farPlay,
			farDownload,
			farSignOut,

			//Solid
			faFileImageSolid,
			faFileVideoSolid,
			faFileWordSolid,
			faFileExcelSolid,
			faFilePowerpointSolid,
			faFileAltSolid,
			faFilePdfSolid,
			faFileSolid,
			faUserSolid,
			faLockSolid,
			faCheckSolid,
			faTimesSolid,
			faExclamationCircleSolid,
			faExclamationTriangleSolid,
			faUserTagSolid,
			faStarSolid,
			faCheckCircleSolid,
			faCircleSolid,
			faInfoCircleSolid,
			faEyeSolid,
			faEyeSlashSolid,
			faQuestionCircleSolid,

			//Brands
			faTwitter,
			faInstagram,
			faLinkedin,

			//Custom Reuzer Icons
			reuzerCertificate,
			reuzerCourse,
			reuzerDashboard,
			reuzerReport,
			reuzerUsers,
			reuzerUser,
			reuzerBell,
			reuzerCog,
			reuzerCogLarge,
			reuzerEye,
			reuzerDownload,
			reuzerUserDouble,
			reuzerPlay,
			reuzerMagnifyingGlass,
			reuzerLibrary,
			reuzerLogout,
			reuzerPadlock,
			reuzerBin,
			reuzerEdit,
			reuzerArrowRight,
			reuzerSuperOwner,
			reuzerCMS,
			reuzerOTJ
		)
	},
}
