var prefix = 'reuzer'
var iconName = 'super-owner'
var width = 1024
var height = 1024
var ligatures = []
var unicode = 'f518'
var svgPathData = `
M272.278 780.457c-150.137 0-272.278-120.428-272.278-268.458s122.142-268.458 272.278-268.458c88.399 0 170.098 41.75 221.289 112.37l368.647 0.596c6.649 0.013 13.033 2.626 17.766 7.296l136.444 134.337c4.847 4.771 7.576 11.282 7.576 18.084s-2.728 13.312-7.576 18.084l-136.037 133.931c-4.746 4.683-11.142 7.296-17.804 7.296-0.013 0-0.038 0-0.051 0l-42.258-0.088c-6.637-0.013-13.020-2.626-17.753-7.296l-48.26-47.511-48.083 47.335c-4.746 4.67-11.142 7.285-17.804 7.285-0.013 0-0.025 0-0.038 0l-28.146-0.038c-6.637-0.013-13.020-2.626-17.766-7.296l-48.032-47.296-47.829 47.080c-4.746 4.683-11.142 7.296-17.804 7.296-0.013 0-0.038 0-0.051 0l-40.101-0.088c-51.458 66.331-131.329 105.543-216.328 105.543zM272.278 294.301c-122.142 0-221.518 97.662-221.518 217.698s99.376 217.698 221.518 217.698c72.98 0 141.279-35.406 182.7-94.706 4.746-6.789 12.512-10.837 20.812-10.837 0.013 0 0.038 0 0.051 0l42.537 0.088 58.197-57.296c9.886-9.746 25.722-9.746 35.62 0l58.438 57.537 7.347 0.013 58.463-57.562c9.886-9.72 25.722-9.733 35.62 0.013l58.679 57.778 21.459 0.038 110.251-108.538-110.682-108.97-371.591-0.609c-8.579-0.013-16.573-4.365-21.243-11.548-41.027-63.121-110.81-100.797-186.659-100.797z

M203.321 615.259c-41.852 0-75.912-34.060-75.912-75.912v-54.682c0-41.865 34.060-75.912 75.912-75.912s75.899 34.060 75.899 75.912v54.682c0.013 41.852-34.047 75.912-75.899 75.912zM203.321 459.5c-13.87 0-25.152 11.294-25.152 25.152v54.682c0 13.87 11.282 25.152 25.152 25.152s25.139-11.294 25.139-25.152v-54.682c0.013-13.857-11.269-25.152-25.139-25.152z
`

export const reuzerSuperOwner = {
	prefix: prefix,
	iconName: iconName,
	icon: [width, height, ligatures, unicode, svgPathData],
}
